<template>
  <KitsForm
    v-if="kits.id"
    ref="kits-form"
    :is-update-form="true"
  />
  <b-skeleton-img
    v-else
    height="500px"
  />
</template>

<script>
import {
  BSkeletonImg,
} from 'bootstrap-vue'
import KitsForm from '../components/KitsForm.vue'
import config from '../kitsConfig'

export default {
  name: 'KitsUpdate',
  components: {
    KitsForm,
    BSkeletonImg,
  },
  data() {
    return {
      error: false,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['kits-form']) this.$refs['kits-form'].showConfirmationModal(next)
  },
  computed: {
    products() {
      return this.$store.state[this.MODULE_NAME].products
    },
    catalog() {
      return this.$store.state.catalogs.catalog
    },
    kits() {
      return this.$store.state[this.MODULE_NAME].kitForm
    },
  },

  mounted() {
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(res => {
      const { data } = res.data
      const kits = this.mappingFields(Object.keys(this.fields), data, {
        name: data.name,
        products: data.products.map(product => ({
          ...product,
          id: product.id,
          qty: product.pivot.product_qty,
        })),
        id: data.id,
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, kits)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    })
  },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
  },

  setup() {
    const MODULE_NAME = 'kits'
    const CATALOG_MODULE_NAME = 'catalogs'
    const { tableColumns, fields } = config()

    return {
      MODULE_NAME,
      tableColumns,
      fields,
      CATALOG_MODULE_NAME,
    }
  },
}
</script>

<style scoped>

</style>
